import React from "react"
import { Link, PageProps } from "gatsby"

import { SEO, Layout, LinkActions } from "../components"
import { useAuthState } from "../features/Auth/state"
import { SignInForm } from "../features/Auth/components"
import { getQueryStringParam } from "../lib/utils"

const LoginPage = ({ location }: PageProps) => {
  const continueTo = getQueryStringParam(location, "continueTo")
  const { isAnonymous, emailAddress, onRefreshUserData } = useAuthState()
  return (
    <Layout isLoginPage>
      <SEO title="Login" />
      <section className="wrapper-small">
        {isAnonymous ? (
          <div>
            <SignInForm
              continueTo={continueTo}
              onRefreshUserData={onRefreshUserData}
            />
          </div>
        ) : (
          <div>
            <h4>
              You're logged in as <strong>{emailAddress}</strong>
            </h4>

            <LinkActions>
              {continueTo ? (
                <Link to={continueTo || "/"}>Continue</Link>
              ) : (
                <Link to="/">Back to Homepage</Link>
              )}
              <Link to="/profile">My profile</Link>
            </LinkActions>
          </div>
        )}
      </section>
    </Layout>
  )
}

export default LoginPage
